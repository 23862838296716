$(document).foundation({
        offcanvas: {
        open_method: 'overlap'
    }
});

var pageState = { foo: "bar" };


document.onload = function () {
    $(window).trigger('resize'); // Force IE to set the right height
}


$(document).ready(function () {

	/** CV UPLOADER BUTTON **/
$("input:file").change(function (){
var filename = $("input:file").val().replace(/C:\\fakepath\\/i, '');
 $(".fileUpload span").html(filename);
 $(".fileUpload").addClass("uploaded");
});




	var maxHeight = -1;

$('.offers-grid .offer-item .inner').each(function() {
     maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
   });

 $('.offers-grid .offer-item .inner').each(function() {
     $(this).height(maxHeight);
   });



$( ".christmas-animation" ).hover(
  function() {
    $( '.christmas-animation .hover' ).fadeIn(1300);
	$( '.christmas-animation .front' ).fadeOut(1300);
  }, function() {
   $('.christmas-animation .hover').fadeOut(1300);
$( '.christmas-animation .front' ).fadeIn(1300);
  }
);



	/*$('.whats-on-landing').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		appendArrows: $('.whats-on-row'),
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});*/
	setupWhatsOnSlider('.whats-on-landing');


	$('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});


/*
	$(document.body).on('click', '.detail-cta a, .offer-item a, .item-link', function () {
        if ($(this).attr('href') == '#lounge') {
            $(this).before('<div id="restaurant-widget"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/LygonLounge/963" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 640px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#grill') {
            $(this).before('<div id="restaurant-widget"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/Standard/LygonBarandGrill/964" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 640px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#wine') {
            $(this).before('<div id="restaurant-widget"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/TheLygonArmsHotelRestaurants/965" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 640px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        } else if ($(this).attr('href') == '#terrace') {
            $(this).before('<div id="restaurant-widget"><a class="restaurant-widget-close" href="#" onclick="$(\'#restaurant-widget\').fadeOut(); return false;">Close</a><iframe src="https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/LygonTerrace/3294" allowtransparency="true" frameborder="0" style="width:100%; border:none; max-width: 540px; height: 640px; "></iframe></div>');
            $('#restaurant-widget').fadeIn();
            return false;
        }

    });
	*/

    $(".video-pop").fancybox({
        type: 'iframe',
        wrapCSS: 'fancybox-video',
        width: '80%',
        height: '80%',
        scrolling: 'no',
        padding: [35, 14, 4, 14]
    });


	// Event tracking
	$('.burger-secondary-nav li a').each( function () {
		if ( $(this).text() == 'Book Now' ) {
			$(this).attr('onclick', "_gaq.push(['_trackEvent', 'Booknow', 'Clicked', 'Menu']);");
		}
	});




 	$('.accordion').accordiom({
        showFirstItem: true,
        afterChange: function () {
            //$(window).trigger('resize');
			var accordionHeight = $('.detail-text').height();
			//alert(accordionHeight);
			$('.detail-slide.slick-slide img').css('padding-bottom', accordionHeight + 'px');
        }
    });

    $('#guestAccordion').accordiom({
        showFirstItem: false, // Either show or hide the first item when loading
    });


    // This button will increment the value
    $('[data-quantity="plus"]').click(function(e){
        // Stop acting like a button
        e.preventDefault();
        // Get the field name
        fieldName = $(this).attr('data-field');
        // Get its current value
        var currentVal = parseInt($('input[name='+fieldName+']').val());
        // If is not undefined
        if (!isNaN(currentVal)) {
            // Increment
            $('input[name='+fieldName+']').val(currentVal + 1);
        } else {
            // Otherwise put a 0 there
            $('input[name='+fieldName+']').val(0);
        }
    });
    // This button will decrement the value till 0
    $('[data-quantity="minus"]').click(function(e) {
        // Stop acting like a button
        e.preventDefault();
        // Get the field name
        fieldName = $(this).attr('data-field');
        // Get its current value
        var currentVal = parseInt($('input[name='+fieldName+']').val());
        // If it isn't undefined or its greater than 0
        if (!isNaN(currentVal) && currentVal > 0) {
            // Decrement one
            $('input[name='+fieldName+']').val(currentVal - 1);
        } else {
            // Otherwise put a 0 there
            $('input[name='+fieldName+']').val(0);
        }
    });




    // Navigation
    $('.primary-nav-toggle').on('click', function () {
        $(this).toggleClass('active');
        $(this).next().slideToggle(500, function () {
            $(document).foundation('equalizer', 'reflow');
        });
        return false;
    });

   // var oldReady = window.slimmage.readyCallback || function () { };
  //  window.slimmage.readyCallback = function () {
     //   oldReady();

        // Homepage slider
      //  if ($('#home-content-inner').length > 0) {
       //     $('#home-content-inner').maximage({
         //       cycleOptions: {
         //           speed: 800,
                    //timeout: 8000,
          //          prev: '.home-slider-prev',
         //           next: '.home-slider-next',
          //          timeout: 0
            //    }
          //  });
           // $('video, object').maximage('maxcover');
            /*$('video').videocover({
                minWidth: 320
            });*/
       // }
  //  }



  $('#home-content-inner').reformSlider({
		arrows: true,
		speed: 6000,
		animationSpeed: 1000
	});




    //$('.home-slider-prev').on('click', function () {
    //    $('#home-content-inner').cycle('prev');
    //    return false;
    //});

    //$('.home-slider-next').on('click', function () {
    //    $('#home-content-inner').cycle('next');
    //    return false;
    //});




    // Landing page slider
    if ($('#landing-content-inner').length > 0) {
        $('#landing-content-inner').maximage({
            cycleOptions: {
                speed: 800,
                timeout: 8000
            }
        });
    }

	$(document.body).on('click', '#landing-content-inner .mc-image', function () { // Click on whole image to slide over
		$('a.landing-slider-next').trigger('click');
	});

    $('.landing-slider-next').on('click', function () {
        if (($(this).hasClass('black')) || ($(this).parent().hasClass('rooms-landing'))) {
            return true;
        }
        var arrow = this;
        $(arrow).hide();
        $('#landing-panels').addClass('open');
        setTimeout(function () {
            $(arrow).addClass('black');
            $(arrow).fadeIn();
            $('.landing-slider-prev').fadeIn();
        }, 1000);
        return false;
    });

    // Skip to grid when URL has the ?back=1 parameter
    if (window.location.href.indexOf('back=1') > -1) {
        $('#landing-panels').addClass('no-transition').addClass('open');
        $('.landing-slider-prev').show();
        $('.landing-slider-next').addClass('black').show();
    }

    $('.landing-slider-prev').on('click', function () {
        if ($(this).parent().hasClass('rooms-landing')) {
            return true;
        }
        $('#landing-panels').removeClass('open');
        $('.landing-slider-prev').hide();
        $('.landing-slider-next').removeClass('black');
        return false;
    });

    $('.landing-grid-item').on('mouseenter', function () {
        $(this).addClass('hover');
    });
    $('.landing-grid-item').on('mouseleave', function () {
        $(this).removeClass('hover');
    });

    /*setTimeout(function () {
		//$('#landing-panels').addClass('open');
		$('.landing-slider-next').trigger('click');
	}, 5000);*/



if ( $('.home-slide.reform-slide iframe').length > 0 ) {
        scaleVideos();
        $(window).on('resize', scaleVideos);
}

function scaleVideos() {
    $('.home-slide.reform-slide iframe').each(function () {
        coverViewport(this);
    });
}



    // Detail pages
    setupSlider($('.detail-slider'));

    var initialSlide = $('#detail-content').attr('data-first-slide');
    if (typeof initialSlide == 'undefined') {
        initialSlide = 0;
    }

    $('#detail-content').slick({
        dots: false,
        fade: false,
        autoplay: false,
        infinite: false,
        arrows: false,
		draggable: false,
        adaptiveHeight: true,
        initialSlide: initialSlide * 1,
        useCSS: false, // hopefully stops glitches
        accessibility: false // stops slide jumping to top of image on change
    });

    $('#detail-content').on('afterChange', function (event, slick, currentSlide) {
        $(window).trigger('resize'); // Force browsers to set the right height
        $("div.book-now a").attr("href", $("#detail-content .slick-active #hiddenBookingLink").val());
    });

    // Manual previous and next buttons
    $(document.body).on('click', '.detail-next', function () {
        $('#detail-content').slick('slickNext');
        history.replaceState(pageState, 'Next Slide', $(this).attr('href'));
        // Load next slide
        setTimeout(loadNextPageAfterSlide, 500);
        return false;
    });
    $(document.body).on('click', '.detail-prev', function () {
        $('#detail-content').slick('slickPrev');
        history.replaceState(pageState, 'Previous Slide', $(this).attr('href'));
        // Load prev slide
        setTimeout(loadPrevPageAfterSlide, 500);
        return false;
    });

    // Allow arrow key navigation
    $(document).keydown(function (e) {
        switch (e.which) {
            case 37: // left
                $('.detail-content-container.slick-slide.slick-current').find('.detail-prev').trigger('click');
                break;

            case 39: // right
                $('.detail-content-container.slick-slide.slick-current').find('.detail-next').trigger('click');
                break;

            default: return;
        }
        e.preventDefault();
    });

    // When swiping, load the next and prev pages as the Next/Prev buttons are not clicked
    $('#detail-content').on('swipe', function (event, slick, direction) {
        // right == prev
        // left == next
        if (direction == 'right') {
            setTimeout(loadPrevPageAfterSlide, 500);
        } else if (direction == 'left') {
            setTimeout(loadNextPageAfterSlide, 500);
        }
    });


    // Load the left and right pages
    loadLeftAndRightPages();



    // Team
    $('.team-person a').fancybox({
        type: 'ajax',
        wrapCSS: 'team-popup',
        padding: 20,
        beforeShow: function (current, previous) {
            $('.detail-slider').slick({
                fade: true,
                speed: 1000
            });
        }
    });



    // Forms
    $('#uploadBtn').on('change', function () {
        $('#uploadFile').val($(this).val());
    });


    // Fixes the height of the burger when open
    $('#main-header a.left-off-canvas-toggle').on('click', function () {
        $('body').addClass('burger');
    });
    $('.left-off-canvas-menu-inner a.left-off-canvas-toggle').on('click', function () {
        $('body').removeClass('burger');
    });


    // Misc
    $('a.open-favourites').on('click', function () {
        $('.favourites-login, .favourites-panel').fadeToggle();
        return false;
    });


    $('.toggle-search').on('click', function () {
        $('.burger-search').slideToggle();
        return false;
    });

    $('.search-reveal-more').on('click', function () {
        $(this).fadeOut(500);
        $('.search-results .hide').fadeIn(500);
        return false;
    });

    $('#timeline-see-more .see-more').on('click', function () {
        //$('#timeline-see-more').fadeOut();
        $("html, body").animate({ scrollTop: $(window).scrollTop() + ($(window).height() - 100) }, 800);
        return false;
    });

    $(document.body).on('click', '.detail-share-social a', function () {
        //		$(this).parent().next().animate({width: 'toggle'}, 300);
        $(this).parent().next().toggleClass('open');
        return false;
    });


    $('a.open-follow-us').on('click', function () {
        $('.follow-us-wrapper').fadeIn().load($(this).attr('href'));
        return false;
    });




    // Toggle search box
    // 	$('a.open-search').on('click', function () {
    // 		$(this).next().toggleClass('open');
    // 		return false;
    // 	});


    // Popup Gallery
    resizeGallery();
    $(window).resize(function () {
        waitForFinalEvent(function () {
            resizeGallery();
        }, 500, 'gallery-resize');
    });

    $('.open-gallery').on('click', function () {
        $('.gallery-wrapper').fadeIn();
        loadGallery($(this).attr('href'));
        return false;
    });

    $('.gallery-list-close').on('click', function () {
        $('.gallery-list').fadeOut();
        return false;
    });

    $('.gallery-wrapper-close').on('click', function () {
        $('.gallery-wrapper').fadeOut();
        $('.gallery-list').fadeIn();
        return false;
    });

    $('.gallery-list-inner ul a').on('click', function () {
        $('.gallery-list').fadeOut();
        $('.gallery-wrapper').fadeIn();
        return false;
    });

    $(document.body).on('click', '.gallery-nav a', function () {
        loadGallery($(this).attr('href'));
        return false;
    });

    // $('.gallery__pagination-pages li').on('click', function (){
    //   $('.gallery__pagination-pages li').addClass('on');
    // })





});



function loadGallery(url) {


    $('#gallery-container').load(url, function () {


        $('.gallery-thumbs').on('init', function (event, slick) {
	        //$('.gallery-slider-full.active').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">').addClass('active');
	        $('.gallery-slider-full.active').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });


        $('.gallery-thumbs').slick({
	        slidesToShow: 7,
	        arrows: false,
	        infinite: false,
	        focusOnSelect: true,
	        responsive: [
		        {
			        breakpoint: 1024,
			        settings: {
				        slidesToShow: 6
			        }
		        },
		        {
			        breakpoint: 640,
			        settings: {
				        slidesToShow: 4
			        }
		        }
	        ]
        });

        $('.gallery-thumbs .gallery-thumb-item').on('click', function () {
	        //console.log('Chaning to ', $(this).data('slick-index'));
	        $('.gallery-thumbs').slick('slickGoTo', $(this).data('slick-index'), true);

	        $('.gallery-thumbs .slick-current').removeClass('slick-current'); // Hack around Slick's deficiencies
	        $( $('.gallery-thumbs .gallery-thumb-item').get(  $(this).data('slick-index'))  ).addClass('slick-current'); // Manually changing the visible item

	        //$('.gallery-slider-full.inactive').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">');
	        $('.gallery-slider-full.inactive').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
	        $('.gallery-slider-full').toggleClass('active inactive');

			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });


        $('.gallery-slider .slick-prev').on('click', function () {
	        var currentSlideIndex = $('.gallery-thumbs .slick-current').index();

	        // Cancel if already at start
	        if (currentSlideIndex == 0) {
		        return;
	        }

	        //console.log('Previousing to ', currentSlideIndex - 1, ' from ', currentSlideIndex);

	        $('.gallery-thumbs').slick('slickGoTo', currentSlideIndex - 1, false);

	        $('.gallery-thumbs .slick-current').removeClass('slick-current'); // Hack around Slick's deficiencies
	        $( $('.gallery-thumbs .gallery-thumb-item').get(  currentSlideIndex - 1)  ).addClass('slick-current'); // Manually changing the visible item

	        //$('.gallery-slider-full.inactive').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">');
	        $('.gallery-slider-full.inactive').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
	        $('.gallery-slider-full').toggleClass('active inactive');

			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });


        $('.gallery-slider .slick-next').on('click', function () {
	        var currentSlideIndex = $('.gallery-thumbs .slick-current').index();

	        // Cancel if already at end
	        if (currentSlideIndex >= $('.gallery-thumbs img').length - 1) {
		        return;
	        }

	        //console.log('Nexting to ', currentSlideIndex + 1, ' from ', currentSlideIndex);

	        $('.gallery-thumbs').slick('slickGoTo', currentSlideIndex + 1, false);

	        $('.gallery-thumbs .slick-current').removeClass('slick-current'); // Hack around Slick's deficiencies
	        $( $('.gallery-thumbs .gallery-thumb-item').get(  currentSlideIndex + 1)  ).addClass('slick-current'); // Manually changing the visible item

	        //$('.gallery-slider-full.inactive').html('<img src="' + $('.gallery-thumbs .slick-current').attr('src') + '">');
	        $('.gallery-slider-full.inactive').css('background-image', 'url(' + $('.gallery-thumbs .slick-current img').attr('src') + ')');
	        $('.gallery-slider-full').toggleClass('active inactive');

			$('.gallery-slide-caption').text($('.gallery-thumbs .slick-current img').attr('title'));
        });


    });
}


function resizeGallery() {
	if (matchMedia('only screen and (max-width: 640px)').matches) { // mobile
		$('.gallery-wrapper').css('width', window.innerWidth + 'px');
	} else { // desktop + tablet
    	$('.gallery-list, .gallery-wrapper').css('height', (window.innerHeight - 92) + 'px');
    	$('.gallery-wrapper').css('width', (window.innerWidth - 80) + 'px');
	}
}

// http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed
var waitForFinalEvent = (function () {
    var timers = {};
    return function (callback, ms, uniqueId) {
        if (!uniqueId) {
            uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
            clearTimeout(timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
    };
})();





function loadLeftAndRightPages() {
    var nextPage = $('.detail-content-container').find('.detail-next').first().attr('href') + '?ajax=true';
    var prevPage = $('.detail-content-container').find('.detail-prev').first().attr('href') + '?ajax=true';

    if (nextPage != 'undefined?ajax=true') {
        $.get(nextPage, function (nextHtml) {
            $('.detail-content-container.slick-current').next().html(nextHtml);
            setupSlider($('.detail-content-container.slick-current').next().find('.detail-slider'));
			setupWhatsOnSlider($('.detail-content-container.slick-current').next().find('.whats-on-landing'));
        });
    }

    if (prevPage != 'undefined?ajax=true') {
        $.get(prevPage, function (prevHtml) {
            $('.detail-content-container.slick-current').prev().html(prevHtml);
            setupSlider($('.detail-content-container.slick-current').prev().find('.detail-slider'));
			setupWhatsOnSlider($('.detail-content-container.slick-current').prev().find('.whats-on-landing'));
        });
    }
}

function loadNextPageAfterSlide() {
    if ($('.detail-content-container.slick-current').next().text() == '') {
        var nextPage = $('.detail-content-container.slick-current').find('.detail-next').first().attr('href');
        $('.detail-content-container.slick-current').next().load(nextPage + '?ajax=true', function () {
            setupSlider($('.detail-content-container.slick-current').next().find('.detail-slider'));
			setupWhatsOnSlider($('.detail-content-container.slick-current').next().find('.whats-on-landing'));
        });

    }
}

function loadPrevPageAfterSlide() {
    if ($('.detail-content-container.slick-current').prev().text() == '') {
        var prevPage = $('.detail-content-container.slick-current').find('.detail-prev').first().attr('href');
        $('.detail-content-container.slick-current').prev().load(prevPage + '?ajax=true', function () {
            setupSlider($('.detail-content-container.slick-current').prev().find('.detail-slider'));
			setupWhatsOnSlider($('.detail-content-container.slick-current').prev().find('.whats-on-landing'));
        });
    }
}

function setupSlider(el) {
    $(el).slick({
        dots: false,
        fade: true,
        arrows: true,
        autoplay: true,
        speed: 5000,
        pauseOnHover: false
    });

    /*$(el).on('init', function () {
		var current = $('#detail-slider').slickCurrentSlide();
		$('#detail-slider').slickGoTo(current);
		console.log('Slider inited');
	});*/
}

function setupWhatsOnSlider(el) {
	$(el).slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		appendArrows: $(el).parent(),
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$(el).find('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});
}









// http://stackoverflow.com/questions/2255291/print-the-contents-of-a-div
function PrintElem(elem) {
    Popup($(elem).html());
}

function Popup(data) {
    var mywindow = window.open('', 'my div', 'height=400,width=600');
    mywindow.document.write('<html><head><title>my div</title>');
    mywindow.document.write('<style type="text/css">.favourites-delete, .favourites-book, .favourites-share, .favourites-sign-out, .favourites-profile-links { display: none; }</style>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(data);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10

    mywindow.print();
    mywindow.close();

    return true;
}




// Close the favourites popups when clicked outside of
$(document).mouseup(function (e) {
    var container = ['#favourites-panel', '.favourites-login'];

    $.each(container, function (key, value) {
        if (!$(value).is(e.target) // if the target of the click isn't the container...
            && $(value).has(e.target).length === 0) // ... nor a descendant of the container
        {
            $(value).hide();
        }
    });
});


function coverViewport(element) {
    var viewportWidth = $(window).innerWidth();
    var viewportHeight = $(window).innerHeight();

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}




window.matchMedia = window.matchMedia || (function( doc, undefined ) {

  "use strict";

  var bool,
      docElem = doc.documentElement,
      refNode = docElem.firstElementChild || docElem.firstChild,
      // fakeBody required for <FF4 when executed in <head>
      fakeBody = doc.createElement( "body" ),
      div = doc.createElement( "div" );

  div.id = "mq-test-1";
  div.style.cssText = "position:absolute;top:-100em";
  fakeBody.style.background = "none";
  fakeBody.appendChild(div);

  return function(q){

    div.innerHTML = "&shy;<style media=\"" + q + "\"> #mq-test-1 { width: 42px; }</style>";

    docElem.insertBefore( fakeBody, refNode );
    bool = div.offsetWidth === 42;
    docElem.removeChild( fakeBody );

    return {
      matches: bool,
      media: q
    };

  };

}( document ));
